import React from 'react'
import Slider from 'react-slick'

import { IKnownFromElement, IKnownFromWrapper } from '../../models/landingpage'
import { PictureTag } from '../helper/picture-tag'

const KnownFromBox = ({ data }: { data: IKnownFromElement }) => {
  return (
    <div
      className={`h-full flex flex-col  sm:mx-8 flex-grow justify-content items-center content-center p-2 hover:shadow-lg`}
    >
      <a
        href={`${
          data.ExternalUrl
            ? data.ExternalUrl
            : data.CtaTarget?.Slug
            ? `${
                data.CtaTarget?.classname?.includes('ategor')
                  ? '/kategorie/'
                  : '/'
              }${data.CtaTarget?.Slug ?? ''}`
            : `#`
        }`}
      >
        <PictureTag
          imageUrl={
            data?.HeroImage?.image.fullpath ?? '/sonstiges/failover.png'
          }
          sizesVW={`100`}
          className={`w-64`}
          altText={data.Name}
        />
      </a>
    </div>
  )
}

const KnownFromWrapper = ({ data }: { data: IKnownFromWrapper }) => {
  const itemcount = 5
  console.log(data)
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow:
      data.KnownFromElements?.length < itemcount
        ? data.KnownFromElements?.length
        : itemcount,
    slidesToScroll:
      data?.KnownFromElements?.length >= 5
        ? itemcount < 5
          ? itemcount
          : 5
        : data?.KnownFromElements?.length < itemcount
        ? data?.KnownFromElements?.length
        : itemcount,
    infinite: false,
    nextArrow: <></>,
    prevArrow: <></>,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }
  return (
    <div className={`w-full bg-${data.Farbe} max-w-full`}>
      <div className={`z-0 container mx-auto py-8 sm:py-16`}>
        <h3
          className={`sm:pt-4 pb-8
           text-2xl sm:text-4xl
         italic font-bold font-swDisp text-center`}
        >
          {data.Headline}
        </h3>

        <div className="z-0 w-full">
          {data && (
            <Slider {...settings}>
              {data.KnownFromElements?.map((child, index) => (
                <KnownFromBox data={child} key={index} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default KnownFromWrapper
